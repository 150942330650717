.layout-sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: $sidebarWidth;
  height: calc(100vh - 4rem);
  z-index: 999;
  user-select: none;
  top: 4rem;
  left: 0rem;
  transition: transform $transitionDuration, left $transitionDuration;
  background-color: var(--surface-overlay);
  // border-radius: var(--border-radius);
  // box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
  //     0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.layout-sidebar-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  position: "relative";
}

.layout-sidebar-toggle {
  position: "absolute";
  left: 0;
  bottom: 0;
  width: "100%";
  padding: 0 1.5rem 0 0.75rem;
}

.layout-menu-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 1rem;
  scrollbar-width: thin;
}

.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    &.layout-menuitem-category {
      margin-top: 0.75rem;

      &:first-child {
        margin-top: 0;
      }
    }

    .layout-menuitem-root-text {
      text-transform: uppercase;
      color: var(--surface-900);
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--text-color);
      transition: color $transitionDuration;
      border-radius: $borderRadius;
      padding: 0.75rem 1rem;
      transition: background-color 0.15s;

      span {
        margin-left: 0.5rem;
      }

      .menuitem-toggle-icon {
        margin-left: auto;
      }

      &:focus {
        @include focused-inset();
      }

      &:hover {
        background-color: var(--surface-hover);
      }

      &.router-link-exact-active {
        font-weight: 700;
        color: var(--primary-color);
      }

      .p-badge {
        margin-left: auto;
      }
    }

    &.active-menuitem {
      > a {
        .menuitem-toggle-icon {
          &:before {
            content: "\e933";
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &.layout-submenu-wrapper-enter {
        max-height: 0;
      }

      &.layout-submenu-wrapper-enter-active {
        overflow: hidden;
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
      }

      &.layout-submenu-wrapper-enter-done {
        transform: none;
      }

      &.layout-submenu-wrapper-exit {
        max-height: 1000px;
      }

      &.layout-submenu-wrapper-exit-active {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
      }

      ul {
        padding-left: 1rem;
      }
    }
  }
}
