.layout-main-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    transition: margin-left $transitionDuration;
    // TODO: Retornar esse atributo quando adicionar o componente AppMenu
    padding: 4.65rem 0.65rem 0.65rem;
}

@media screen and (max-width: 600px) {
    .layout-main-container {
        padding: 4.65rem 0rem 0.65rem;
    }
}
