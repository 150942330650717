* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $fontSize !important;
}

body {
    font-family: var(--font-family);
    color: var(--text-color);
    background-color: var(--surface-ground);
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
button {
    text-decoration: none;
    color: var(--primary-color);
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
}

* {
    scrollbar-width: 7px;
    scrollbar-color: var(--primary-color) var(--surface-100);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: var(--surface-100);
    border-radius: var(--border-radius);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 20px;
}
