//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
.signout-button {
  .p-menuitem-text,
  .p-menuitem-icon {
    color: var(--danger-color) !important;
  }
}

.p-card {
  background-color: var(--surface-overlay);
}

.layout-wrapper {
  height: 100vh;
}

.datatable-row-dark {
  background-color: var(--surface-100) !important;
}

.p-multiselect.p-multiselect-chip.p-multiselect-sm .p-multiselect-label {
  font-size: 0.875rem;
  padding: 0.51786rem 0.875rem;
}

.p-multiselect.p-multiselect-chip.p-multiselect-sm .p-multiselect-label.p-multiselect-label-empty {
  font-size: 0.875rem;
  padding: 0.875rem;
}

.p-multiselect.p-multiselect-chip.p-multiselect-sm .p-multiselect-label .p-multiselect-token {
  font-size: 0.875rem;
  padding: 0.357rem 0.875rem;
}

.divider-margin {
  width: 100%;
  margin: 1.25rem 0;
  padding: 0 0.5rem;
}

.accordion-header-default-border {
  border-radius: 4px;
  border: 1px solid #bbb;
}
.accordion-content-default-border {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #bbb;
  border-top: none;
}
.p-accordion-header.p-highlight.accordion-header-default-border {
  border-radius: 4px 4px 0px 0px;
}
.accordion-header-module-online {
  border-radius: 4px;
  border: 1px solid var(--success-color);
}
.p-accordion-header.p-highlight.accordion-header-module-online {
  border-radius: 4px 4px 0px 0px;
}
.accordion-content-module-online {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid var(--success-color);
  border-top: none;
}
.accordion-header-module-offline {
  border-radius: 4px;
  border: 1px solid var(--danger-color);
}
.p-accordion-header.p-highlight.accordion-header-module-offline {
  border-radius: 4px 4px 0px 0px;
}
.accordion-content-module-offline {
  border-radius: 0px 0px 4px 4px;
  border: 1px solid var(--danger-color);
  border-top: none;
}

.list-item {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.list-item-border {
  border: 1px solid #bbb;
}

.p-inputnumber-input {
  min-width: 42px !important;
  width: "100%" !important;
}

.p-overlaypanel-close-icon.pi.pi-times {
  padding-top: 1px;
}

.macro {
  height: 450px;
  overflow: auto;
}
.dragging-over-light {
  background-color: var(--primary-50);
}
.dragging-over-dark {
  background-color: var(--primary-900);
}
.dragging-light {
  background-color: var(--primary-100);
}
.dragging-dark {
  background-color: var(--primary-800);
}
.state-value {
  min-width: 10rem;
  max-width: 10rem;
}

.p-progressbar.ongoing-color .p-progressbar-value {
  background: var(--warning-color) !important;
}
.p-progressbar.success-color .p-progressbar-value {
  background: var(--success-color) !important;
}
.p-progressbar.error-color .p-progressbar-value {
  background: var(--danger-color) !important;
}
